@import "../../../assets/styles/index.scss";

.rootMenu {
  display: "flex";
  width: 15%;
  border-radius: 0px;
  min-width: fit-content;
  min-height: 84vh;
  box-shadow: 0 2px 4px 0 rgba(10, 17, 40, 0.1), 0 0 1px 0 rgba(10, 17, 40, 0.1) !important;
}

@media (max-width: 768px) {
  .rootMenu {
    width: 18%;
    min-width: auto;
  }
}
.paper {
  margin-right: 20px;
}

.link {
  text-decoration: none !important;
  color: currentcolor !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: table-cell;
}

.menuList {
  width: 15%;
  padding-top: 32%;
  background-color: $WhiteBase;
  min-height: 100%;
  margin-top: 4px !important;
  box-shadow: none;
  padding-left: 0px;
  padding-right: 0%;
  min-width: 100%;
}

.menuItem {
  font-family: $FontFamily2;
  font-size: $FontSize14;
  line-height: $LineHeight157;
  letter-spacing: $LetterSpacing02;
  color: $BlackBase !important;
  padding: 5px 14px 5px 25px;
  display: flex;
  vertical-align: middle;
  margin-top: 15px;
  margin-bottom: 15px;
}

.menuItemSelected {
  font-family: $FontFamily2 !important;
  font-size: $FontSize14 !important;
  line-height: $LineHeight157 !important;
  letter-spacing: $LetterSpacing02 !important;
  color: $Primary600 !important;
  padding: 5px 14px 5px 25px;
  display: flex;
  vertical-align: middle;
  margin-top: 15px;
  margin-bottom: 15px;
  min-height: fit-content;
}

.backGroundSelected {
  background-color: $Primary100 !important;
  border-radius: 0px 16px 16px 0px;
  min-height: 56px;
  overflow: hidden;
  box-sizing: border-box;
  white-space: nowrap;
  text-align: left;
  margin-right: 3%;
}

.backGroundUnSelected {
  min-height: 56px;
  overflow: hidden;
  box-sizing: border-box;
  white-space: nowrap;
  text-align: left;
}

.MuiAccordionSummary-content {
  font-family: $FontFamily2;
  font-size: $FontSize14;
  line-height: $LineHeight157;
  letter-spacing: $LetterSpacing02;
  color: $BlackBase !important;
  padding: 5px 14px 5px 25px;
  display: flex;
  vertical-align: middle;
  margin-top: 15px;
  margin-bottom: 15px;
  display: -webkit-inline-box !important;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.label {
  cursor: pointer;
  vertical-align: super;
  margin-left: 25px;
  padding-top: 20px;
}

.MuiAccordionSummary-root.Mui-expanded {
  min-height: none !important;
}
