@import "../../../assets/styles/index.scss";

//------------------------------------------------------------------

.recoveryPasswordTitle {
  color: $BlackBase !important;
  font-family: $FontFamily2 !important;
  font-size: $FontSize25 !important;
  letter-spacing: $LetterSpacing015 !important;
  text-align: center;
  padding: 0px !important;
  margin: 0px !important;
}

.instructions {
  color: $BlackBase !important;
  font-family: $FontFamily4 !important;
  font-size: $FontSize14 !important;
  letter-spacing: $LetterSpacing02 !important;
  text-align: center;
  padding: 0px !important;
  margin: 0px !important;
  padding-top: 10px !important;
}

.instructions-two {
  color: $BlackBase !important;
  font-family: $FontFamily3 !important;
  font-size: $FontSize14 !important;
  letter-spacing: $LetterSpacing02 !important;
  text-align: center;
  padding: 0px !important;
  margin: 0px !important;
  padding-top: 10px !important;
  padding-bottom: 20px !important;
}

/*------------------------------------------------------------------
  [ Button ]*/
.loginButton {
  color: #fff !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  padding: 0 20px !important;
  width: 100% !important;
  height: 50px !important;
  border-radius: $BorderRadious8 !important;
  background-color: $Primary700 !important;
  font-family: $FontFamily3 !important;
  font-size: $FontSize16 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: $LineHeight157 !important;
  letter-spacing: $LetterSpacing05 !important;
  margin-bottom: 10px !important;
}
