@import "../../../assets/styles/index.scss";

.MuiToolbar-root {
  background: $WhiteBase !important;
  min-height: 72px;
  flex-grow: 1;
  // box-shadow: 0 2px 4px 0 rgba(10, 17, 40, 0.1), 0 0 1px 0 rgba(10, 17, 40, 0.1);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}
#headerLogo {
  width: 150px;
  position: relative;
}
.body1 {
  font-family: $FontFamily2;
  font-size: $FontSize20;
  font-stretch: normal;
  font-style: normal;
  line-height: $LineHeight16;
  letter-spacing: $LetterSpacing02;
  color: $BlackBase !important;
  flex-grow: 1;
  text-align: end;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
}

.body2{
  font-family: $FontFamily3;
  font-size: $FontSize14;
  line-height: $LineHeight18;
  letter-spacing: $LetterSpacing025;
  font-stretch: normal;
  font-style: normal;
  line-height: $LineHeight16;
  color: $Primary700;
  flex-grow: 1;
  text-align: end;
  display: flex;
  flex-direction: column;
  margin-top: 4px;
}

.regular {
  color: $WhiteBase !important;
  background-color: $Gray1 !important;
  border-color: $Primary500;
  border-width: 3px;
  border-style: solid;
  text-decoration: none !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.language {
  font-family: $FontFamily3;
}
