
.ant-alert {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    padding: 8px 15px 8px 37px;
    word-wrap: break-word;
    border-radius: 2px;
}
.ant-alert-with-description {
    position: relative;
    padding: 15px 15px 15px 64px;
    color: rgba(0, 0, 0, 0.65);
    line-height: 1.5715;
    border-radius: 2px;
}
.ant-alert-with-description .ant-alert-message {
    display: block;
    margin-bottom: 4px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
}
.ant-alert-message {
    color: rgba(0, 0, 0, 0.85);
}

.ant-alert-with-description .ant-alert-description {
    display: block;
}

.ant-alert-description {
    display: none;
    font-size: 14px;
    line-height: 22px;
}

.ant-alert-with-description .ant-alert-icon {
    position: absolute;
    top: 16px;
    left: 24px;
    font-size: 24px;
}

.ant-alert-icon {
    position: absolute;
    top: 12.0005px;
    left: 16px;
}
.anticon {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.ant-alert-error {
    background-color: #fff2f0;
    border: 1px solid #ffccc7;
}
.ant-alert-error .ant-alert-icon {
    color: #ff4d4f;
}
