@import "../../assets/styles/index.scss";

.headerAccordion {
  display: flex !important;
  align-content: center !important;
  justify-content: flex-end !important;
  flex-flow: row;
  width: 100%;
}

.borderAccordion {
  border: 1px solid $Gray2 !important;
}

.accordionAdd {
  border: 1px solid $Gray2 !important;
  background-color: $Primary100 !important;
}
