@import "../../assets/styles/index.scss";

.title {
  margin-left: 0.5%;
  text-align: left !important ;
  font-size: $FontSize20 !important;
  line-height: $LineHeight32 !important;
  letter-spacing: $LetterSpacing015 !important;
  font-family: $FontFamily2 !important;
}

.titleCard {
  margin-left: 0.5%;
  text-align: left !important ;
  font-size: $FontSize16 !important;
  line-height: $LineHeight24 !important;
  letter-spacing: $LetterSpacing02 !important;
  font-family: $FontFamily3 !important;
  font-weight: normal !important;
}

