@import "./Index.scss";

.containerField {
  padding-bottom: 2%;
}
.fieldLeft {
  width: 40%;
  padding-left: 2%;
  padding-right: 4%;
}
.fieldRigth {
  width: 40%;
}

// .MuiFormControl-root {
//   width: 90% !important;
// }

.MuiFormLabel-root.Mui-focused {
  color: $Primary800 !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: $Gray1 !important;
}

.textBold {
  font-family: $FontFamily2;
  line-height: $LineHeight24;
  letter-spacing: $LetterSpacing02;
  font-size: $FontSize16;
}

.textUrl {
  font-family: $FontFamily3;
  line-height: $LineHeight24;
  letter-spacing: $LetterSpacing02;
  font-size: $FontSize16;
}

.textMiniBold {
  font-family: $FontFamily2;
  line-height: $LineHeight24;
  letter-spacing: $LetterSpacing02;
  font-size: $FontSize16;
}

.miniText {
  font-family: $FontFamily3;
  line-height: $LineHeight24;
  letter-spacing: $LetterSpacing02;
  font-size: $FontSize16;
  color: $BlackLow;
  padding-left: 1%;
}

.urlExample {
  font-family: $FontFamily3;
  line-height: $LineHeight24;
  letter-spacing: $LetterSpacing02;
  font-size: $FontSize16;
  color: $Primary700;
}

.MuiAppBar-colorPrimary {
  background-color: #fff !important;
}
.MuiTab-textColorInherit {
  color: $Primary700 !important;
  font-family: $FontFamily3 !important;
  line-height: $LineHeight24 !important;
  letter-spacing: $LetterSpacing02 !important;
  font-size: $FontSize16 !important;
}
.PrivateTabIndicator-colorSecondary-3 {
  background-color: $Primary700 !important;
}

.PrivateTabIndicator-colorSecondary-44 {
  background-color: $Primary700 !important;
}

.PrivateTabIndicator-colorSecondary-7 {
  background-color: $Primary700 !important;
}

.PrivateTabIndicator-colorSecondary-63 {
  background-color: $Primary700 !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: $Primary700 !important;
}
