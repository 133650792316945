@import "../../../assets/styles/index.scss";

.componentSpace {
  padding: 12px 10px;
}

.content {
  background-color: $Gray3;
  // display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: #f3f3f3;
}


//card

.MuiCard-root {
  height: 100%;
}

.MuiCardContent-root {
  padding: 0px !important;
  padding-top: 16px !important;
  padding-right: 16px !important;
  padding-left: 16px !important;
  margin-top: 4px !important;
}