@import "../../../../assets/styles/index.scss";

.rowSection {
  display: flex;
  align-items: center;
}

.centerImage {
  display: grid;
  justify-content: center;
  /* align-items: center; */
}

.imageEdit {
  width: var(--width);
  height: var(--heigth);
  justify-content: space-around;
  display: block;
}

.iconEditInSection {
  width: $FontSize16;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardImages {
  width: 200px;
  height: 100px;
  display: block;
  align-items: center;
  justify-content: center;
  background-color: $Gray3 !important;
}

.cardImagesSquare {
  width: 72px;
  height: 72px;
  display: block;
  align-items: center;
  justify-content: center;
  background-color: $Gray2 !important;
  margin-right: 15px;
}

.widthAndHeightImageSection {
  width: auto !important;
  max-width: 170px !important;
  height: 59px !important;
  cursor: pointer !important;
}

.iconXCardImages {
  display: flex;
  flex-direction: row-reverse;
  transform: translate(0%, -20%);

  a {
    width: 20px;
    height: 20px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-color: #ffff;
    border-radius: 50%;
    cursor: pointer;
    color: #757575;
    padding: 0.4%;
    transform: translate(35%, -25%);
    box-shadow: 0 4px 8px 0 rgba(10, 17, 40, 0.1),
      0 0 1px 0 rgba(10, 17, 40, 0.1);
    // height: 22px;
  }
}

.widthAndHeightImageSectionSquare {
  width: 72px !important;
  height: 72px !important;
  transform: translate(0%, -35%);
  cursor: pointer !important;
  z-index: 1;
  object-fit: cover;
}

.widthAndHeightImageSectionSquareDisabled {
  width: 72px !important;
  height: 72px !important;
  transform: translate(0%, -35%);
  cursor: not-allowed !important;
  z-index: 1;
  object-fit: cover;
}

.iconXCardImagesSquare {
  display: flex;
  flex-direction: row-reverse;
  a {
    width: 22px;
    height: 22px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-color: #ffff;
    border-radius: 50%;
    cursor: pointer;
    color: #757575;
    padding: 0.4%;
    transform: translate(35%, -25%);
    box-shadow: 0 4px 8px 0 rgba(10, 17, 40, 0.1),
      0 0 1px 0 rgba(10, 17, 40, 0.1);
    z-index: 3;
    // height: 20px;
  }
}

.iconImageEditInSection {
  width: $FontSize16;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 2px;
  margin-top: -1px;
}

.iconImageEditInSectionSquare {
  width: $FontSize16;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 3px;
  margin-top: -1px;
}

.iconImageEditInSectionSquareDisabled {
  width: $FontSize16;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 3px;
  margin-top: -1px;
  cursor: not-allowed !important;
}

.imageEdit {
  font-size: 35px !important;
  color: $BlackHalf !important;
  cursor: pointer !important;
  display: flex;
  justify-content: center;
}

.image-pic {
  width: 100%;
  height: max-content;
  background: white;
  position: relative;
  display: grid;
  align-items: flex-end;
  transition: 0.6 ease-out;
}

.image-pic:hover::before {
  opacity: 1;
}

.image-pic:hover .icon-text {
  opacity: 1;
  transform: translateY(0);
}

.image-pic img {
  width: 100%;
  max-height: var(--heigth);
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.image-pic::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  width: 100%;
  height: var(--heigth);
  background: linear-gradient(
    to top,
    var(--backgroundColor),
    transparent
  ) !important;
  z-index: 2;
  opacity: 0;
  transition: 0.5s;
}

.image-pic .icon-text {
  display: grid;
  justify-content: center;
  z-index: 3;
  color: white;
  opacity: 0;
  transform: translateY(var(--translateY)) !important;
  transition: 0.5s;
}

.icon-text h4 {
  margin: 0;
  font-size: 35px;
  color: white;
}

.icon-text p {
  letter-spacing: 2px;
  font-size: 15px;
  margin-top: 8px;
}

.contenIconInImage {
  display: flex;
  flex-direction: row;
}

.icon-text a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 1px solid white;
  border-radius: 50%;
  text-decoration: none;
  margin: 5px;
}

.icon-text a:hover {
  border: 2px solid white;
  width: 32px;
  height: 32px;
}

.textLogo {
  margin-left: 2%;
  margin-right: 2%;
  color: $BlackBase;
  font-size: $FontSize16;
  font-family: $FontFamily4;
  line-height: $LineHeight24;
  letter-spacing: $LetterSpacing02;
  display: flex;
  align-self: center;
}

.adjustPositionXLeft {
  margin-left: 4.5%;
}

.adjustPositionXRigth {
  margin-right: 4.5%;
}

.adjustPositionYTop {
  margin-top: 2%;
}

.adjustPositionYBottom {
  margin-bottom: 3%;
}

.textAccordion {
  font-size: $FontSize16 !important;
  font-family: $FontFamily3 !important;
  line-height: $LineHeight24 !important;
  letter-spacing: $LetterSpacing02 !important;
  color: $BlackHigh !important;
}

.bgAccordion {
  background-color: $Gray4 !important;
}

.bgAddNewComponent {
  background-color: $Primary100;
  border-radius: $BorderRadious8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px 0px 16px;
  min-height: 48px;
}

.textAddNewComponent {
  font-size: $FontSize16 !important;
  font-family: $FontFamily3 !important;
  line-height: $LineHeight24 !important;
  letter-spacing: $LetterSpacing02 !important;
  color: $BlackHigh !important;
}

.iconAddNewComponent {
  color: $Primary700;
  width: 14px !important;
  cursor: pointer;
}

.containerSocialNetWorkInContact {
  display: flex !important;
  align-content: center !important;
  align-items: center !important;
  margin-left: 2%;
}

.containerIconContact {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-right: 2%;
}

.iconContact {
  display: flex !important;
  align-content: center !important;
  justify-content: center !important;
  color: $Primary700 !important;
  width: $FontSize39 !important;
}

.urlContact {
  width: 100%;
}

.lpSection {
  border-radius: 8px;
  border: solid 1px $Gray2;
}

.inlineSomeItems {
  display: flex;
  align-content: center;
  justify-content: flex-start;
}

.editStreamingModal {
  max-width: 890px !important;
}

// Accordion

.accordionContent {
  display: block;
  width: 100%;
}

.accordionCenterLine {
  display: flex;
  // align-items: center;
}

.accordionCompleteInputs {
  width: 100%;
  margin-left: 1%;
  margin-right: 1%;
}

.buttonsFixed {
  background-color: $WhiteBase;
  min-height: 72px;
  flex-grow: 1;
  box-shadow: 4px 2px 0px 2px rgba(10, 17, 40, 0.1),
    1px 0 0 2px rgba(10, 17, 40, 0.1);
  width: 100%;
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 0;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.buttonsBottomInit {
  display: flex;
  margin: 0.6% 2%;
  font-family: $FontFamily3;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.47px;
  color: #0a1128;
  align-items: center;
}

.buttonsBottomEnd {
  display: flex;
  margin: 0.6% 2%;
  align-items: center;
}

.profilePhoto {
  color: $WhiteBase !important;
  background-color: $Gray1 !important;
  border-color: $Primary500;
  border-width: 3px;
  border-style: solid;
  text-decoration: none !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// .MuiTypography-body1 {
//   font-size: $FontSize16 !important;
//   font-family: $FontFamily3 !important;
//   line-height: $LineHeight24 !important;
//   letter-spacing: $LetterSpacing02 !important;
//   color: $BlackHigh !important;
// }

.MuiAccordionDetails-root {
  overflow: auto;
}

@media (max-width: 768px) {
  .rowSection {
    display: block;
  }

  .accordionCenterLine {
    display: block;
  }
}
