@import "../../../assets/styles/index.scss";

/*------------------------------------------------------------------
[ login ]*/
.container-login100 {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: center !important;
  align-items: center !important;
  background-image: url($LoginBackgroundImage);
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  height: 100vh;
  background-color: $WhiteBase;
}

// body {
//   background: (rgba(128,128,128,.075))!important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
//   // text-align: center
// }

.wrap-login100 {
  width: 320px !important;
  height: auto;
  padding: 2% 4% !important;
  background: $WhiteBase !important;
  border-radius: $BorderRadious16 !important;
  overflow: hidden !important;
  border-style: solid !important;
  border-color: white;
}

.centerHorizontalItem {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1920px) {
  .wrap-login100 {
    width: 320px !important;
  }
}

@media only screen and (max-width: 1366px) {
  .wrap-login100 {
    width: 310px !important;
  }
}

@media only screen and (max-width: 414px) {
  .wrap-login100 {
    width: 250px !important;
  }
}

@media only screen and (max-width: 360px) {
  .wrap-login100 {
    width: 220px !important;
  }
}

/*------------------------------------------------------------------
[ Form ]*/

.logo100-form {
  width: 37%;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 4%;
}

.login-input {
  margin-bottom: 8% !important;
}

.login-input-label {
  color: $BlackHalf !important;
  font-family: $FontFamily3 !important;
  font-size: $FontSize16;
  letter-spacing: $LetterSpacing02;
}

/*------------------------------------------------------------------
[seccion Text]*/
.login-rememberMe {
  color: $BlackHalf !important;
  align-self: center;
  font-family: $FontFamily3;
  font-size: $FontSize14;
  line-height: $LineHeight68;
  letter-spacing: $LetterSpacing02;
}

.login-form-forgot {
  color: $Primary800 !important;
  align-self: center;
  font-family: $FontFamily3;
  font-size: $FontSize14;
  line-height: $LineHeight68;
  letter-spacing: $LetterSpacing02;
  text-decoration: underline;
}

.rowForgotPassword {
  margin-bottom: 2%;
  display: flex;
  justify-content: space-between;
}

.buttonLogin {
  background-color: $Primary800 !important;
  width: 100%;
  font-family: $FontFamily3 !important;
  font-size: $FontSize16 !important;
  letter-spacing: $LetterSpacing015 !important;
  border-radius: $BorderRadious8 !important;
  height: fit-content;
  min-height: 50px;
  color: white;
  text-transform: capitalize;
}
.MuiFormControl-root {
  width: 100% !important;
}

//Input Material UI

// .MuiInputBase-input {
//   font-family: $FontFamily3 !important;
//   font-size: $FontSize16 !important;
//   line-height: $LineHeight157 !important;
//   letter-spacing: $LetterSpacing015 !important;
//   color: rgba(10, 17, 40, 0.6) !important;
// }

.MuiInputLabel-outlined {
  font-family: $FontFamily3 !important;
  font-size: $FontSize16 !important;
  letter-spacing: $LetterSpacing015 !important;
  color: rgba(10, 17, 40, 0.6) !important;
}
